import React, { useState, useEffect } from 'react';
import { useStatePersist } from 'use-state-persist';
import 'url-search-params-polyfill';

import fetchJsonp from 'fetch-jsonp';
import SmartFeedJobCard from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedJobCard';

import { useFiltersContext } from '@accrosoft-ltd/vf-careers-site-theme/src/context/FiltersContext';

import SmartFeedFilters from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedFilters';

require('es6-promise').polyfill();

let prevCount = 0;
let currentCount = 0;
let keywordTime = null;

const setVacancyLoadingBackup = function setVacancyLoadingBackup(
  loading,
  vacancyLoading
) {
  if (loading) {
    return true;
  }

  return false;
};

const SmartFeedList = ({
  siteConfig,
  apiKey = '',
  group = false,
  smartfeedCompanyData = {},
  trackEvent,
  useGroupVacsLink,
  vacancyLoading = true,
  setVacancyLoading = setVacancyLoadingBackup,
}) => {
  const id = 1;
  const [vacancy, setVacancy] = useState([]);
  const [vacancyTitle, setVacancyTitle] = useState('Loading vacancies...');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);

  // Filter States
  const {
    filtersState: { keywords, filters },
    loading,
    setLoading,
    resetFilters,
  } = useFiltersContext();

  const [sortBySelection, setSortBySelection] = useStatePersist(
    '@sortBy',
    'postingdate'
  );
  const [sortByDirSelection, setSortByDirSelection] = useStatePersist(
    '@sortDir',
    'desc'
  );

  const [viewBySelection, setViewBySelection] = useStatePersist(
    '@sortView',
    'grid'
  );

  const [vacsCount, setVacsCount] = useState(0);
  const [loadMoreText, setLoadMoreText] = useState(false);

  let groupOrIdParam = 'id';

  if (group === true) {
    groupOrIdParam = 'group';
  }

  useEffect(() => {
    clearTimeout(keywordTime);

    keywordTime = setTimeout(() => {
      fetchVacancies();
    }, 500);
  }, [keywords]);

  useEffect(() => {
    fetchVacancies();
  }, [filters]);

  const fetchVacancies = async (name, loadMore) => {
    setLoadMoreText('Loading.....');

    if (!loadMore) {
      prevCount = 0;
      currentCount = 0;
      setLoading(true);
    }

    if (name === 'reset') {
      resetFilters();
    }

    function getAdvertData(jobs, prevCount, currentCount) {
      return Promise.all(
        jobs.slice(prevCount, currentCount).map(async (id) => {
          return fetchJsonp(
            siteConfig.sfAPIEndpoint + '/CareerPage/GetItem?id=' + id,
            {
              timeout: 15000,
            }
          ).then(function (response) {
            return response.json();
          });
        })
      );
    }

    try {
      const postingTypeValue =
        name === 'reset' ? 'careers' : filters.postingtype || 'careers';

      const query = name === 'reset' ? '' : '&' + buildQuery();

      fetchJsonp(
        siteConfig.sfAPIEndpoint +
          '/CareerPage/GetResultList?' +
          groupOrIdParam +
          '=' +
          apiKey +
          '&sortby=' +
          sortBySelection +
          '&sortdir=' +
          sortByDirSelection +
          '&postingtype=' +
          postingTypeValue +
          '&keywords=' +
          keywords +
          query,
        {
          timeout: 15000,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(async function (jsonList) {
          prevCount = currentCount;
          currentCount = currentCount + 12;

          await getAdvertData(jsonList, prevCount, currentCount).then(function (
            data
          ) {
            if (loadMore) {
              setVacancy(vacancy.concat(data));
            } else {
              setVacancy(data);
            }

            if (jsonList.length === 0) {
              setVacancyTitle('Showing 0 out of 0 vacancies');
            } else {
              let count = currentCount;
              if (currentCount > jsonList.length) count = jsonList.length;
              setVacancyTitle(
                'Showing ' + count + ' out of ' + jsonList.length + ' vacancies'
              );
            }

            setInitialLoading(false);

            setLoading(false);
            setVacancyLoading(false);

            setLoadMoreText('Load More');
            setVacsCount(jsonList.length);
          });
        });
    } catch (error) {
      setLoading(false);
      setVacancyLoading(false);
      console.error(error.message, 'error');
    }
  };

  const loadMore = () => {
    fetchVacancies(undefined, true);
    if (trackEvent) {
      trackEvent({
        eventCategory: 'SmartFeedListComponent',
        eventAction: 'loadMore',
        eventLabel: 'Loaded more vacancies',
      });
    }
  };

  const resetVacs = () => {
    fetchVacancies('reset', false);
    if (trackEvent) {
      trackEvent({
        eventCategory: 'SmartFeedListComponent',
        eventAction: 'resetVacs',
        eventLabel: 'Reset the filters',
      });
    }
  };

  const searchVacs = function searchVacs() {
    fetchVacancies('search', false);
    if (trackEvent) {
      trackEvent({
        eventCategory: 'SmartFeedListComponent',
        eventAction: 'searchVacs',
        eventLabel: 'Triggered a search',
      });
    }
  };

  useEffect(() => {
    if (!isInitialLoad) {
      searchVacs();
    }
  }, [sortBySelection, sortByDirSelection]);

  const buildQuery = function buildQuery() {
    let query = [];

    for (let key in filters) {
      if (filters.hasOwnProperty(key)) {
        query.push(
          encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
        );
      }
    }

    return query.join('&');
  };

  useEffect(() => {
    if (isInitialLoad) {
      if (trackEvent) {
        trackEvent({
          eventCategory: 'SmartFeedListComponent',
          eventAction: 'initalised',
          eventLabel: apiKey,
        });
      }

      if (URLSearchParams) {
        var urlParams = new URLSearchParams(window.location.search);
        var entries = urlParams.entries();
      }
      let temp = {};
      for (let pair of entries) {
        temp[pair[0]] = pair[1];
      }

      const timeOut = setTimeout(() => {
        fetchVacancies();

        setIsInitialLoad(false);
      }, 50);
      return () => clearTimeout(timeOut);
    }
  }, [isInitialLoad]);

  return !vacancyLoading &&
    smartfeedCompanyData &&
    smartfeedCompanyData.CompanyName ? (
    <section className="main-vacs-wrap">
      <div className="row">
        <div className="col-12">
          <h3 className="vacancies-landing__header__heading">{vacancyTitle}</h3>
          {siteConfig.displayVacanciesSortOptions && (
            <div className="vacancies-landing__header__sortbox">
              <div className="ml-auto">
                <div className="sortbox__select_wrap">
                  <label htmlFor="vf-vacs-sort-by" className="sortbox__label">
                    Sort by:
                  </label>
                  <select
                    id="vf-vacs-sort-by"
                    className="VF-form-control onchange-search form-control"
                    onChange={(e) => setSortBySelection(e.target.value)}
                    placeholder={'Please select an option'}
                    title={'Select an option to sort vacancies by'}
                    selected={sortBySelection}
                  >
                    <option value="postingdate">Posting Date</option>
                    <option value="expirydate">Expiry Date</option>
                    <option value="industrysector">Industry</option>
                    <option value="category">Category</option>
                    <option value="jobtype">Job Type</option>
                    <option value="region">Region</option>
                    <option value="location">Location</option>
                    <option value="sublocation">Sub Location</option>
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row vf-careers-site">
        {!vacancyLoading &&
          (loading ? (
            'Loading vacancies...'
          ) : (
            <>
              {vacancy &&
              vacancy !== [] &&
              vacancy !== '[]' &&
              !initialLoading ? (
                <>
                  <div className="col-12 col-lg-4">
                    <SmartFeedFilters
                      siteConfig={siteConfig}
                      apiKey={siteConfig.apiKey || apiKey}
                      group={
                        siteConfig.group
                          ? siteConfig.group
                          : groupOrIdParam === 'id'
                          ? false
                          : groupOrIdParam === 'group'
                          ? true
                          : false
                      }
                      groupOrIdParam={groupOrIdParam}
                    />
                  </div>
                  <div className="col-12 col-lg-8 px-0">
                    {vacancy && vacancy.length > 0 ? (
                      vacancy.map((job, i) => (
                        <SmartFeedJobCard
                          job={job}
                          key={i}
                          viewBySelection={'list'}
                          apiKey={apiKey}
                          siteConfig={siteConfig}
                          useGroupVacsLink={useGroupVacsLink}
                          trackEvent={trackEvent}
                        />
                      ))
                    ) : (
                      <div className="py-2 job-card card job-card-no-results">
                        <div className="job-card-details">
                          <div className="no-results">
                            Sorry, we don't have any vacancies matching your
                            search.
                          </div>
                          <button
                            id="reset_vacs"
                            className="nav-action-sm"
                            onClick={() => resetVacs()}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className="no-vacancies">Loading vacancies...</div>
              )}

              <div className="load-more-wrap col-12">
                {currentCount < vacsCount && (
                  <a
                    className="nav-action-sm"
                    role="button"
                    onClick={() => loadMore()}
                  >
                    {loadMoreText}
                  </a>
                )}
              </div>
            </>
          ))}
      </div>
    </section>
  ) : (
    <div className="page-loading-block">
      <div className="loading-panel">
        <div className="loading-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <span className="loading-text">
          Please wait while we load the vacancies available...
        </span>
      </div>
    </div>
  );
};

export default SmartFeedList;
