import React from 'react';
import { useStatePersist } from 'use-state-persist';

import { Link } from 'gatsby';

import ScreenReader from '@accrosoft-ltd/vf-careers-site-theme/src/components/ScreenReader';

const categories = require('../../../categories.json');

const SmartFeedJobCard = ({
  job,
  apiKey,
  viewBySelection,
  siteConfig,
  useGroupVacsLink,
  trackEvent,
}) => {
  const [savedJobs, setSavedJobs] = useStatePersist('@savedJobs_' + apiKey, []);

  var category = categories.find((c) => c.name === job.JobCategory);

  function unSaveJob(id) {
    const newSavedJobsArray = savedJobs.filter((e) => e !== id.toString());
    setSavedJobs(newSavedJobsArray);
    if (trackEvent) {
      trackEvent({
        eventCategory: 'SmartFeedListComponent',
        eventAction: 'unSaveJob',
        eventLabel: encodeURIComponent(id),
      });
    }
  }

  function saveJob(id) {
    setSavedJobs((savedJobsOld) => [...savedJobsOld, id]);
    if (trackEvent) {
      trackEvent({
        eventCategory: 'SmartFeedListComponent',
        eventAction: 'saveJob',
        eventLabel: encodeURIComponent(id),
      });
    }
  }

  const calculateSalary = function calculateSalary(
    Currency,
    SalaryFrom,
    SalaryTo,
    SalaryType,
    SalaryPeriod
  ) {
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    let NewSalary;
    let NewSalaryFrom;
    let NewSalaryTo;

    let currencyFormatted = Currency;

    if (Currency === '&pound;') {
      currencyFormatted = '£';
    }

    if (SalaryTo === 0) {
      NewSalary = SalaryType;
    } else if (SalaryFrom === SalaryTo) {
      if (SalaryFrom >= 1000) {
        // Remove decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        // Add two decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      NewSalary = NewSalaryFrom + ' ' + SalaryPeriod;
    } else {
      if (SalaryFrom >= 1000) {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      if (SalaryTo >= 1000) {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed());
      } else {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2));
      }

      NewSalary = NewSalaryFrom + ' - ' + NewSalaryTo + ' ' + SalaryPeriod;
    }
    return NewSalary;
  };

  const FormatDateTime = function FormatDateTime(JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)));
    var dateFormatOptions = new Object({});
    dateFormatOptions.weekday = 'long';
    dateFormatOptions.year = 'numeric';
    dateFormatOptions.month = 'short';
    dateFormatOptions.day = 'numeric';
    return expDate.toLocaleDateString(undefined, dateFormatOptions);
  };

  const getSummary = function getSummary(summary, description) {
    if (summary) {
      return summary;
    } else {
      let alteredDescription = description.replace(/<[^>]*>?/gm, '');
      let tempSummary = alteredDescription.substring(0, 397);
      if (tempSummary.length === 397) {
        tempSummary += '...';
      }
      return tempSummary;
    }
  };

  const slugify = function slugify(text) {
    return text
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters

      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-'); // Replace multiple - with single -
  };

  const onError = (e) => {
    e.target.onerror = null;
    e.target.src = 'https://logos.vacancy-filler.co.uk/BCMFareva.png';
  };

  return (
    <div
      className={`py-2 col-12 column ${
        viewBySelection === 'grid' ? 'col-md-6' : ''
      }`}
    >
      <div className="job-card card" id={new Buffer(job.Id).toString('base64')}>
        <div className="job-card-details">
          <div className="d-flex justify-content-between">
            <div className="job-card-header">
              <Link
                to={
                  siteConfig.companyCareersSite
                    ? `${
                        useGroupVacsLink === true
                          ? '/group-vacancies/'
                          : `/vacancies/`
                      }vacancy/` +
                      new Buffer(job.Id).toString('base64') +
                      '/' +
                      slugify(job.JobTitle)
                    : `/${apiKey}${
                        useGroupVacsLink === true
                          ? '/group-vacancies/'
                          : `/vacancies/`
                      }vacancy/` +
                      new Buffer(job.Id).toString('base64') +
                      '/' +
                      slugify(job.JobTitle)
                }
                className="job-card-title"
                style={{ color: category?.colour }}
              >
                {job.JobTitle}
              </Link>
              <div className="job-card-location">
                {[
                  job.Location.trim(),
                  job.SubLocation.trim(),
                  job.PostCode.trim(),
                  job.Country.trim(),
                ]
                  .filter((each) => each)
                  .join(', ')}
              </div>
            </div>
            <div className="job-card-actions">
              <div
                title="Save this job for later"
                className="job-card-savejob"
                onClick={() => {
                  if (
                    savedJobs.filter((e) => e == job.Id.toString()).length > 0
                  ) {
                    unSaveJob(job.Id);
                  } else {
                    saveJob(job.Id);
                  }
                }}
              >
                <button className="btn btn-link">
                  {savedJobs.filter((e) => e == job.Id.toString()).length >
                  0 ? (
                    <span
                      className="fas fa-heart"
                      style={{ color: category?.colour }}
                    ></span>
                  ) : (
                    <span
                      className="far fa-heart"
                      style={{ color: category?.colour }}
                    ></span>
                  )}
                </button>
              </div>
              <div className="job-card-reader">
                <ScreenReader
                  text={`${job.JobTitle}, 
                this role is for ${job.Region}, 
                part of ${job.JobCategory}, 
                based in ${job.Location}. 
                The contract type for this position is ${job.JobType} / ${
                    job.JobTypeTime
                  }, 
                this vacancy expires on ${FormatDateTime(job.ExpiryDate)}. 
                Please click the apply button below to find out more.`}
                  colour={category?.colour}
                />
              </div>
            </div>
          </div>
          <div className="job-card-reference">
            {'(Reference: ' + job.AdvertReference + ')'}
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="job-card-meta">
              <div className="job-card-meta-item">
                <span style={{ color: category?.colour }}>Closing Date:</span>
                {FormatDateTime(job.ExpiryDate)}
              </div>
              <div className="job-card-meta-item">
                <span style={{ color: category?.colour }}>Salary:</span>
                {calculateSalary(
                  job.Currency,
                  job.SalaryFrom,
                  job.SalaryTo,
                  job.SalaryType,
                  job.SalaryPeriod
                )}
              </div>
              <div className="job-card-meta-item mt-4">{job.Summary}</div>
            </div>
            <div className="img-wrapper p-3 d-flex flex-column align-items-center">
              <img
                className="img-fluid"
                style={{ borderRadius: category ? '50%' : '' }}
                height={150}
                width={150}
                src={`/logos/${category?.img}`}
                alt={job.JobCategory}
                onError={onError}
              />
              <p className="text-center">
                <strong>{job.JobCategory}</strong>
              </p>
            </div>
          </div>
          <div className="job-card-buttons-wrap">
            <Link
              className="job-card-button btn--secondary"
              style={{
                borderColor: category?.colour,
              }}
              to={
                siteConfig.companyCareersSite
                  ? `${
                      useGroupVacsLink === true
                        ? '/group-vacancies/'
                        : `/vacancies/`
                    }vacancy/` +
                    new Buffer(job.Id).toString('base64') +
                    '/' +
                    slugify(job.JobTitle)
                  : `/${apiKey}${
                      useGroupVacsLink === true
                        ? '/group-vacancies/'
                        : `/vacancies/`
                    }vacancy/` +
                    new Buffer(job.Id).toString('base64') +
                    '/' +
                    slugify(job.JobTitle)
              }
            >
              More details
            </Link>
            <Link
              className="job-card-button btn--primary"
              style={{
                backgroundColor: category?.colour,
                borderColor: category?.colour,
              }}
              to={
                siteConfig.companyCareersSite
                  ? `${
                      useGroupVacsLink === true
                        ? '/group-vacancies/'
                        : `/vacancies/`
                    }` +
                    'vacancy/' +
                    new Buffer(job.Id).toString('base64') +
                    '/' +
                    slugify(job.JobTitle) +
                    '?action=applyNow' +
                    '&' +
                    window.location.search.replace('?', '')
                  : `/${apiKey}${
                      useGroupVacsLink === true
                        ? '/group-vacancies/'
                        : `/vacancies/`
                    }vacancy/` +
                    new Buffer(job.Id).toString('base64') +
                    '/' +
                    slugify(job.JobTitle) +
                    '?action=applyNow' +
                    '&' +
                    window.location.search.replace('?', '')
              }
            >
              Apply Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartFeedJobCard;
