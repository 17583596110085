import React, { useState, useEffect } from 'react';
import Layout from '@accrosoft-ltd/vf-careers-site-theme/src/components/Layout';

import SmartFeedList from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedList';
import SmartFeedFilters from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedFilters';
import SmartFeedNavigation from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedNavigation';
import SmartFeedJobAlerts from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedJobAlerts';

import { useFiltersContext } from '@accrosoft-ltd/vf-careers-site-theme/src/context/FiltersContext';

import fetchJsonp from 'fetch-jsonp';

import { useCookies } from 'react-cookie';
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from '@accrosoft-ltd/vf-careers-site-theme/src/components/Analytics';
import { useTracking } from 'react-tracking';

export default function HomepagePage(props) {
  const {
    filtersState: { filters },
    onSelectionChange,
  } = useFiltersContext();

  const {
    pageContext: { config },
  } = props;

  const siteConfig = config;

  const apiKey = siteConfig.apiKey || props.smartfeedID;
  const gdprKey = siteConfig.gdprKey || props.apiKey || props.smartfeedID;
  const groupOrIdParam = siteConfig.group ? 'group' : 'id';
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);
  const [vacancyLoading, setVacancyLoading] = useState(true);

  const [cookies, setCookie] = useCookies(['ap-signals-user-id']);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    'ap-signals-session-id'
  );

  const { trackEvent } = useTracking(
    { page: 'Homepage', location: props.location, apiKey },
    {
      dispatch: (data) =>
        trackAnalyticsEvent(
          data,
          cookies['ap-signals-user-id'],
          sessionIDValue,
          siteConfig
        ),
    }
  );

  useEffect(() => {
    trackAnalyticsPageView(
      props.location,
      cookies['ap-signals-user-id'],
      sessionIDValue,
      siteConfig
    );
  }, [cookies, props.location, sessionIDValue, siteConfig]);

  useEffect(() => {
    if (apiKey) {
      let companyDetailsEndpoint = 'GetCompanyDetails';

      if (groupOrIdParam === 'group') {
        companyDetailsEndpoint = 'GetGroupDetails';
      }

      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
            '/CareerPage/' +
            companyDetailsEndpoint +
            '?id=' +
            apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonList) {
            fetchJsonp(
              siteConfig.sfAPIEndpoint +
                '/CareerPage/' +
                'GetApplicantGetwayURL' +
                '?companyId' +
                '=' +
                gdprKey,
              {
                timeout: 15000,
              }
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (appgatway) {
                setVacanciesRouteLoading(false);
                if (
                  jsonList &&
                  jsonList.CompanyDetails &&
                  jsonList.CompanyDetails.length > 0
                ) {
                  setSmartfeedCompanyData(jsonList.CompanyDetails[0]);
                } else {
                  setSmartfeedCompanyData(jsonList);
                }
                setAppGatewayURL(appgatway);
              })
              .catch(function (ex) {
                setVacanciesRouteLoading(false);
                setVacanciesRouteError(ex);
                console.error(ex, 'error');
              });
          })
          .catch(function (ex) {
            setVacanciesRouteLoading(false);
            setVacanciesRouteError(ex);
            console.error(ex, 'error');
          });
      } catch (ex) {
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, 'error');
      }
    } else {
      setVacanciesRouteLoading(false);
      setVacanciesRouteError('No API key was provided.');
    }
  }, [apiKey, gdprKey, groupOrIdParam, siteConfig.sfAPIEndpoint]);

  const companyName =
    smartfeedCompanyData?.CompanyName || siteConfig?.CompanyName || '';

  const onNavChange = (value) => {
    var temp = {
      target: {
        name: 'postingtype',
        value,
      },
    };
    onSelectionChange(temp);
  };

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={apiKey}
      groupOrIdParam={groupOrIdParam}
      smartfeedCompanyData={smartfeedCompanyData}
      appGatewayURL={appGatewayURL}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      trackEvent={trackEvent}
      title={`${companyName} Careers`}
      description={`Careers at ${companyName}`}
    >
      {!vacanciesRouteLoading &&
      (siteConfig.siteConfig || smartfeedCompanyData) &&
      apiKey ? (
        <>
          <div
            className="banner"
            style={{
              backgroundRepeat: 'no-repeat',
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/banner.jpg)`,
            }}
          >
            <h1 className="text-center text-white">
              {smartfeedCompanyData.CompanyName} Careers
            </h1>
          </div>
          <div className="container py-3">
            <div className="mainpage-content">
              <h2>About us</h2>
              <p>Welcome to our careers page – we’re delighted you’re thinking about joining the team.</p>
              <p>BCM is part of <a target="_blank" href="https://www.fareva.com/en/our-commitments/our-code-of-ethics">Fareva</a>, one of the world's leading subcontractors in the Industrial and Household, Cosmetics and Pharmaceuticals fields. Joining Fareva means becoming part of an international, family business, with around 13,000 passionate experts at the heart of its DNA.</p>
              <p>At BCM, we have over 80 years’ experience in manufacturing pharmaceutical and beauty products for many well-known household brands. We operate from the famous D10 building in Beeston, Nottingham – which we’re proud to say is MHRA and FDA approved.</p>
              <p>Our teams work hard every day to ensure we delight our customers, so if you enjoy providing outstanding customer service – we would love to hear from you.</p>
            </div>
            <div className="row">
              <div className="col-12 mb-3">
                <SmartFeedNavigation
                  active={filters?.postingtype || 'Careers'}
                  onNavChange={onNavChange}
                />
              </div>
            </div>
            {filters?.postingtype !== 'Alerts' ? (
              <div className="row">
                <div className="col-12 p-0">
                  <SmartFeedList
                    siteConfig={siteConfig}
                    apiKey={siteConfig.apiKey || apiKey}
                    group={
                      siteConfig.group
                        ? siteConfig.group
                        : groupOrIdParam === 'id'
                        ? false
                        : groupOrIdParam === 'group'
                        ? true
                        : false
                    }
                    smartfeedCompanyData={
                      siteConfig.smartfeedCompanyData || smartfeedCompanyData
                    }
                    trackEvent={trackEvent}
                    vacancyLoading={vacancyLoading}
                    setVacancyLoading={setVacancyLoading}
                  />
                </div>
              </div>
            ) : (
              <div className="row justify-content-center">
                <div className="col-12 col-lg-8">
                  <SmartFeedJobAlerts
                    siteConfig={siteConfig}
                    trackEvent={trackEvent}
                    apiKey={apiKey}
                    gdprKey={gdprKey}
                    group={siteConfig.group}
                    location={siteConfig.showLocationJA}
                    region={siteConfig.showRegionJA}
                    category={siteConfig.showCategoryJA}
                    industry={siteConfig.showIndustryJA}
                    jobType={siteConfig.showJobTypeJA}
                    jobTime={siteConfig.showJobTimeJA}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="notfound">
          <section className="app-content">
            <div className="app-content-content">
              <div className="container">
                <div className="loading-panel">
                  <div className="loading-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span className="loading-text">
                    Please wait... If content is not shown soon then you may
                    have the wrong URL.
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}
